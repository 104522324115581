import React from "react";
import Typography from "@mui/material/Typography";

export default function SpecialistLendingThankYou() {
  return (
    <>
      <Typography paragraph={true}>
        Thanks for contacting us! We will get in touch with you shortly.
      </Typography>
      <Typography paragraph={true}>
        If you would rather speak to someone now, please call 01454 809 300
        (Monday to Thursday 9am to 5:30pm and Friday 9am to 5:30pm)
      </Typography>

      <Typography paragraph={true}>
        YOUR PROPERTY MAY BE REPOSSESSED IF YOU DO NOT KEEP UP REPAYMENTS ON A
        MORTGAGE OR ANY DEBT SECURED ON IT
      </Typography>
    </>
  );
}
